<template>
  <div>
    <b-modal
      id="modal-1"
      title="Choose one.."
      ref="initiate-gp"
      hide-footer
      modal-class="modal-info"
      centered
      size="lg"
    >
      <div v-if="!ischoosed">
        <div>
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            v-model="nonImportedFilters.search"
          />
        </div>
        <div
          class="d-flex justify-content-between mt-1 align-items-center"
          v-for="standard in nonImportedStandards"
          :key="standard._id"
        >
          <div>
            <h4 class="text-primary">{{ standard.name }}</h4>
            <p>{{ standard.description }}</p>
          </div>
          <div>
            <button
              class="btn btn-primary btn-sm"
              @click="choose(standard._id)"
            >
              Choose
            </button>
          </div>
        </div>

        <!-- <button class="btn btn-primary" @click="Next()">Next</button> -->
      </div>
      <div v-else>
        <div class="float-right">
          <button class="btn btn-warning btn-sm" @click="Previous()">
            Choose Another
          </button>
        </div>
        <h6>You have Chosen</h6>
        <h2>{{ choosedStandard.name }}</h2>

        <input
          type="text"
          class="form-control mb-1"
          placeholder="Name of Assessment"
          v-model="name_assessment"
        />
        <label for="">Select Subset</label>
        <select
          name=""
          id=""
          class="form-control mb-1"
          v-model="selectedSubset"
        >
          <option :value="null" selected>-- All--</option>
          <option
            :value="subset._id"
            v-for="subset in choosedStandard.subsets"
            :key="subset._id"
          >
            {{ subset.name }}
          </option>
        </select>

        <button class="btn btn-primary" @click="handleNewGapSubmit">
          Start
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-select-org"
      ref="modal-select-org"
      hide-footer
      modal-class="modal-info"
      centered
      size="lg"
      :no-close-on-backdrop="true"
      ok-only
    >
      <template #modal-header="{}">
        <h5 class="text-warning mb-0">
          <feather-icon icon="AlertTriangleIcon" class="mr-25" />Warning
        </h5>
      </template>
      <div class="d-flex flex-column align-items-center jusify-content-between">
        <b-form-group label="Choose Organization" class="w-100">
          <b-form-select
            name=""
            id=""
            class="form-control"
            @change="selectChoose(selectedWorkspace)"
            v-model="selectedWorkspace"
            placeholder="Choose Organization"
          >
            <option
              :value="value"
              v-for="(value, key) in this.$store.state.app.workspaces"
              :key="key"
            >
              {{ value.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <h5 class="my-1">-- Or --</h5>
        <div
          class="d-flex flex-wrap flex-row align-items-center justify-content-center border rounded p-1 w-100"
        >
          <b-button
            size="sm"
            variant="primary"
            class="m-25"
            @click="handleLogoutClick"
          >
            <feather-icon icon="LogOutIcon" class="mr-25" />
            Logout</b-button
          >
        </div>
      </div>
    </b-modal>

    <div class="row" v-if="currentWorkspaceModules.includes('GRC')">
      <div class="col-8">
        <div class="card" v-if="userIsVendor() == false">
          <div class="card-body">
            <h4>Pending Tasks</h4>
            <button
              class="btn btn-primary btn-sm float-right mb-1"
              @click="gotoTask()"
            >
              View All
            </button>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Task</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Priority</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(task, index) in tasks"
                    :key="task._id"
                    @click="handleTaskClick(task._id)"
                    class="cursor-pointer"
                  >
                    <th scope="row" style="width: 60px">{{ index + 1 }}</th>
                    <td>
                      <p class="font-weight-bolder mb-0">
                        {{ task.title }}
                      </p>
                    </td>
                    <td>
                      <p class="font-weight-bold mb-0">
                        {{ task.due_date | moment }}
                      </p>
                    </td>
                    <td>
                      <b-badge variant="danger" v-if="task.priority == 3">
                        High
                      </b-badge>
                      <b-badge variant="warning" v-if="task.priority == 2">
                        Medium
                      </b-badge>
                      <b-badge variant="success" v-if="task.priority == 1">
                        Low
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card" v-if="userIsVendor() == false">
          <div class="card-body">
            <h4>Ongoing Gap Assessments</h4>
            <button
              class="btn btn-primary btn-sm float-right mb-1"
              @click="gotogaps()"
            >
              View All
            </button>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col" class="text-center">Progress</th>
                    <th scope="col" class="text-center">Controls</th>
                    <th scope="col" class="text-center">Gap</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(gap, index) in this.gaps"
                    :key="gap._id"
                    @click="handleRowClick(gap._id)"
                    class="cursor-pointer"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td style="width: 120px">
                      <p
                        class="mb-0 font-weight-bolder text-truncate"
                        style="width: 120px"
                        v-b-tooltip.hover.top.v-secondary
                        :title="gap.title"
                      >
                        {{ gap.title }}
                      </p>
                    </td>

                    <td>
                      <!-- <b-avatar
                        :text="gap.comepletion.toString() +'%'"
                        variant="light-warning"
                      /> -->
                      <!-- <donut-bar
                        :percentage="Number(100 - Number(gap.completion))"
                        :displayValue="100 - gap.completion + '%'"
                        size="xsm"
                        :uniqueId="gap._id"
                      /> -->
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <progress-badge
                          :value="gap.completion"
                          :colorsReversed="true"
                          :min="0"
                          :max="100"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-avatar
                          :text="gap.total_controls.toString()"
                          variant="light-primary"
                        />
                      </div>
                    </td>
                    <td>
                      <!-- <b-avatar
                        :text="gap.gap.toString() + '%'"
                        variant="light-success"
                      /> -->

                      <!-- <donut-bar
                        :percentage="Number(gap.gap.toFixed(2))"
                        :displayValue="gap.gap + '%'"
                        size="xsm"
                        :uniqueId="gap._id + 1"
                      /> -->
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <progress-badge :value="gap.gap" :min="0" :max="100" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card" v-if="userIsVendor() && trpAssessments !== null">
          <div
            class="card-header d-flex flex-row align-items-center justify-content-between"
          >
            <h4 class="mb-0">Ongoing T.P.R Assessments</h4>
            <b-button
              variant="primary"
              size="sm"
              @click="handleVendorAssessmentViewAllClick()"
              >View All</b-button
            >
          </div>
          <div class="card-body">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col" class="text-center">
                    Questionnaires
                  </th>

                  <th role="columnheader" scope="col" class="text-center">
                    Progress
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="assessment in trpAssessments"
                  :key="assessment._id"
                  role="row"
                  class="cursor-pointer"
                  @click="handleAssessmentClick(assessment._id)"
                >
                  <td role="cell" style="width: 120px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 120px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.title"
                    >
                      <p class="font-weight-bolder text-truncate mb-0">
                        {{ assessment.title }}
                      </p>
                    </div>
                  </td>

                  <td role="cell" style="width: 120px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 120px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.description"
                    >
                      <p class="font-weight-bold text-truncate mb-0">
                        {{ assessment.description }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div class="d-flex justify-content-center">
                      <b-avatar
                        :text="assessment.total_questionnaires.toString()"
                        variant="light-primary"
                      />
                    </div>
                  </td>

                  <td role="cell">
                    <div class="d-flex justify-content-center">
                      <!-- <donut-bar
                        :percentage="Number(assessment.completion.toFixed(2))"
                        :displayValue="assessment.completion + '%'"
                        size="xsm"
                        :uniqueId="assessment._id + 2"
                        :colorsReversed="true"
                      /> -->
                      <progress-badge
                        :colorsReversed="true"
                        :value="assessment.completion.toFixed(0)"
                        :min="0"
                        :max="100"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <h4>
              <b>{{ greeting }}</b>
            </h4>

            <h5>
              {{ $store.state.app.user.firstname }}
              {{ $store.state.app.user.lastname }}!
            </h5>

            <h1
              class="mb-75 mt-2 pt-50"
              v-if="greeting == 'Good Morning'"
              style="font-size: 2.5rem; float: right"
            >
              🌼
            </h1>
            <h1
              class="mb-75 mt-2 pt-50"
              v-if="greeting == 'Good Afternoon'"
              style="font-size: 2.5rem; float: right"
            >
              🌞
            </h1>
            <h1
              class="mb-75 mt-2 pt-50"
              v-if="greeting == 'Good Evening'"
              style="font-size: 2.5rem; float: right"
            >
              ☕️
            </h1>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6>Upcoming Activities</h6>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-tabs
      v-if="
        $store.state.app.currentcid &&
        typeof $store.state.app.currentcid != 'undefined' &&
        $store.state.app.currentcid != null &&
        $store.state.app.workspaces != null &&
        $store.state.app.workspaces.length > 0
      "
    >
      <b-tab
        title="GRC Dashboard"
        v-if="currentWorkspaceModules.includes('GRC')"
      >
        <div class="row">
          <div class="col-8">
            <div class="card" v-if="userIsVendor() == false">
              <div class="card-body">
                <h4>Pending Tasks</h4>
                <button
                  class="btn btn-primary btn-sm float-right mb-1"
                  @click="gotoTask()"
                >
                  View All
                </button>
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Task</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(task, index) in tasks"
                        :key="task._id"
                        @click="handleTaskClick(task._id)"
                        class="cursor-pointer"
                      >
                        <th scope="row" style="width: 60px">{{ index + 1 }}</th>
                        <td>
                          <p class="font-weight-bolder mb-0">
                            {{ task.title }}
                          </p>
                        </td>
                        <td>
                          <p class="font-weight-bold mb-0">
                            {{ task.due_date | moment }}
                          </p>
                        </td>
                        <td>
                          <b-badge variant="danger" v-if="task.priority == 3">
                            High
                          </b-badge>
                          <b-badge variant="warning" v-if="task.priority == 2">
                            Medium
                          </b-badge>
                          <b-badge variant="success" v-if="task.priority == 1">
                            Low
                          </b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card" v-if="userIsVendor() == false">
              <div class="card-body">
                <h4>Ongoing Gap Assessments</h4>
                <button
                  class="btn btn-primary btn-sm float-right mb-1"
                  @click="gotogaps()"
                >
                  View All
                </button>
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" class="text-center">Progress</th>
                        <th scope="col" class="text-center">Controls</th>
                        <th scope="col" class="text-center">Gap</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(gap, index) in this.gaps"
                        :key="gap._id"
                        @click="handleRowClick(gap._id)"
                        class="cursor-pointer"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td style="width: 120px">
                          <p
                            class="mb-0 font-weight-bolder text-truncate"
                            style="width: 120px"
                            v-b-tooltip.hover.top.v-secondary
                            :title="gap.title"
                          >
                            {{ gap.title }}
                          </p>
                        </td>

                        <td>
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <progress-badge
                              :value="gap.completion"
                              :colorsReversed="true"
                              :min="0"
                              :max="100"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <b-avatar
                              :text="gap.total_controls.toString()"
                              variant="light-primary"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <progress-badge
                              :value="gap.gap"
                              :min="0"
                              :max="100"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card" v-if="userIsVendor() && trpAssessments !== null">
              <div
                class="card-header d-flex flex-row align-items-center justify-content-between"
              >
                <h4 class="mb-0">Ongoing T.P.R Assessments</h4>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="handleVendorAssessmentViewAllClick()"
                  >View All</b-button
                >
              </div>
              <div class="card-body">
                <table role="table" class="table table-hover">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col">Name</th>
                      <th role="columnheader" scope="col">Description</th>
                      <th role="columnheader" scope="col" class="text-center">
                        Questionnaires
                      </th>

                      <th role="columnheader" scope="col" class="text-center">
                        Progress
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      v-for="assessment in trpAssessments"
                      :key="assessment._id"
                      role="row"
                      class="cursor-pointer"
                      @click="handleAssessmentClick(assessment._id)"
                    >
                      <td role="cell" style="width: 120px">
                        <div
                          class="d-flex justify-content-start"
                          style="width: 120px"
                          v-b-tooltip.hover.top.v-secondary
                          :title="assessment.title"
                        >
                          <p class="font-weight-bolder text-truncate mb-0">
                            {{ assessment.title }}
                          </p>
                        </div>
                      </td>

                      <td role="cell" style="width: 120px">
                        <div
                          class="d-flex justify-content-start"
                          style="width: 120px"
                          v-b-tooltip.hover.top.v-secondary
                          :title="assessment.description"
                        >
                          <p class="font-weight-bold text-truncate mb-0">
                            {{ assessment.description }}
                          </p>
                        </div>
                      </td>

                      <td role="cell">
                        <div class="d-flex justify-content-center">
                          <b-avatar
                            :text="assessment.total_questionnaires.toString()"
                            variant="light-primary"
                          />
                        </div>
                      </td>

                      <td role="cell">
                        <div class="d-flex justify-content-center">
                          <progress-badge
                            :colorsReversed="true"
                            :value="assessment.completion.toFixed(0)"
                            :min="0"
                            :max="100"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card">
              <div class="card-body">
                <h4>
                  <b>{{ greeting }}</b>
                </h4>

                <h5>
                  {{ $store.state.app.user.firstname }}
                  {{ $store.state.app.user.lastname }}!
                </h5>

                <h1
                  class="mb-75 mt-2 pt-50"
                  v-if="greeting == 'Good Morning'"
                  style="font-size: 2.5rem; float: right"
                >
                  🌼
                </h1>
                <h1
                  class="mb-75 mt-2 pt-50"
                  v-if="greeting == 'Good Afternoon'"
                  style="font-size: 2.5rem; float: right"
                >
                  🌞
                </h1>
                <h1
                  class="mb-75 mt-2 pt-50"
                  v-if="greeting == 'Good Evening'"
                  style="font-size: 2.5rem; float: right"
                >
                  ☕️
                </h1>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h6>Upcoming Activities</h6>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab
        title="Threat Managment Dashboard"
        v-if="currentWorkspaceModules.includes('Threat Management')"
      >
        <Tmdash></Tmdash>
      </b-tab>
    </b-tabs> -->
  </div>
</template>

<script>
import DonutBar from "../../components/DonutBar.vue";
import ProgressBadge from "../../components/ProgressBadge.vue";
import Tmdash from "./components/v1.vue";
import UserMixins from "@/mixins/UserMixins";
import GapAssessmentMixins from "../../mixins/GapAssessmentMixins";
import moment from "moment";
import {
  BTable,
  BAvatar,
  BBadge,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
  BModal,
  BFormSelect,
} from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import StandardMixins from "../../mixins/StandardMixins";
export default {
  components: {
    DonutBar,
    BTable,
    BAvatar,
    BBadge,
    moment,
    BButton,
    ProgressBadge,
    BTabs,
    BTab,
    Tmdash,
    BModal,
    BFormSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/coming-soon.svg"),
      tasks: [],
      gaps: [],
      trpAssessments: null,
      componentKey: 0,
      ischoosed: false,
      nonImportedStandards: [],
      nonImportedFilters: {
        search: null,
        page: 1,
      },
      nonImportedPagination: {
        lastPage: 1,
        perPage: 10,
        total: 1,
      },
      choosedStandard: [],
      name_assessment: "",
      selectedSubset: "",
      currentWorkspace: [],
      currentWorkspaceModule: [],
      selectedWorkspace: [],
      is_currentmodule: this.$store.state.app.currentWorkspace,
    };
  },
  computed: {
    currentWorkspaceModules() {
      return this.$store.state.app.currentWorkspaceModules;
    },
    greeting() {
      var myDate = new Date();
      var hrs = myDate.getHours();

      var greet;

      if (hrs < 12) greet = "Good Morning";
      else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
      else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
      return greet;
    },
  },
  watch: {
    nonImportedFilters: {
      handler: function (newFilters) {
        this.getAllNonImportedStandardsAndSetData(newFilters);
      },
      deep: true,
    },
    is_currentmodule() {
      this.loadCurrentModules();
    },
  },
  mounted() {
    // if (
    //   !this.$store.state.app.currentcid ||
    //   typeof this.$store.state.app.currentcid == "undefined" ||
    //   this.$store.state.app.currentcid === null
    // ) {
    //   if (
    //     this.$store.state.app.workspaces &&
    //     this.$store.state.app.workspaces.length == 1
    //   ) {
    //     this.selectChoose(this.$store.state.app.workspaces[0]);
    //   } else {
    //     this.loadOrgSelectModal();
    //   }
    // } else {
    //   this.load();
    //  this.loadCurrentModules();
    //   this.getAllNonImportedStandardsAndSetData();
    // }

    this.load();
    this.loadCurrentModules();
    this.getAllNonImportedStandardsAndSetData();
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  mixins: [
    UserMixins,
    ResponseMixins,
    ThirdPartyRisksMixins,
    StandardMixins,
    GapAssessmentMixins,
  ],
  methods: {
    handleLogoutClick() {
      this.$store.dispatch("notification/resetNotifications");
      this.$store.dispatch("app/logout").then(() => {
        const url = "/login";
        // if (this.$store.state.app.user.is_staff) url = '/organizations'
        this.$router.replace(url);
        console.log("Logged Out");
      });
    },

    selectChoose(payload) {
      this.$store.dispatch("app/changetenant", payload).then(
        (response) => {
          this.loadCurrentModules();
          const workspaces = this.$store.state.app.workspaces;
          workspaces.map((item) => {
            if (item.tenant_uid === payload.tenant_uid) {
              this.$store.commit("currentWorkspace", item);
              const currentModules = [];
              item.enabled_modules.map((item) => {
                currentModules.push(item.name);
              });
              this.$store.commit("currentWorkspaceModule", currentModules);
            }
          });
          if (
            workspaces.some((space) => space.tenant_uid === payload.tenant_uid)
          ) {
            this.$store.commit("SET_TENANT_ID", payload.tenant_uid);
          }
          this.$store
            .dispatch("app/getCurrentUser")
            .then((res) => {
              if (
                this.$cookies.get("redirectGRCPath") &&
                this.$cookies.get("redirectGRCPath") != "null"
              ) {
                const path = this.$cookies.get("redirectGRCPath");
                this.$router.push(`/${path}`).then((res) => {
                  this.$cookies.set(
                    "redirectGRCPath",
                    null,
                    null,
                    null,
                    process.env.VUE_APP_DOMAIN
                  );
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.handleError(err);
            });

          this.$store
            .dispatch("app/getCurrentUserDepartment", { root: true })
            .then((res) => {
              console.log("Department", res);
              if (
                !res.data.data.department ||
                res.data.data.department === null ||
                typeof res.data.data.department == "undefined"
              ) {
                this.selectedWorkspace = [];
                const url = "/settings";

                this.$router.push(url).then(() => {
                  this.$router.go();
                });

                // window.location.reload();
              } else {
                window.location.reload();
                this.selectedWorkspace = [];
              }
            });
        },
        (error) => {
          console.log("Something went wrong");
        }
      );
    },
    loadOrgSelectModal() {
      this.$refs["modal-select-org"].show();
    },
    loadCurrentModules() {
      // debugger
      if (this.$store.state.app.currentWorkspaceModules.includes("GRC")) {
        this.getTasks();
        this.getGaps();
      }
    },
    handleNewGapSubmit() {
      this.storeGapAndSetData({
        // notes: this.gapNote,
        title: this.name_assessment,
        standardId: this.choosedStandard._id,
        subsetId: this.selectedSubset,
      });
      // this.load();
      // this.hideModal();
      this.hideGapInitModal();
    },

    storeGapAndSetData(obj) {
      //  this.showOverlay = true;
      this.storeStandardToGap(obj)
        .then((res) => {
          this.handleResponse(res);
          //  alert(JSON.stringify(res))
          //  this.load();
          // this.$route.push('/gapassessment')
          this.$router.push(
            `/gapassessment/${res.data.data._id}/controlgapassessment`
          );
        })
        .catch((err) => {
          //  this.handleError(err);
        })
        .finally(() => {
          //  this.showOverlay = false;
        });
    },
    choose(id) {
      // this.showOverlay();
      this.importedStandard(id)
        .then((res) => {
          // this.load();
          this.choosedStandard = res.data.data;
          if (this.choosedStandard.length != 0) {
            this.ischoosed = true;
          }
          // this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          // this.hideOverlay();
        });
    },
    getAllNonImportedStandardsAndSetData(filters) {
      this.overlay = true;
      this.getAllStandards(filters)
        .then((res) => {
          console.log("Non Imported Standards", res);
          this.nonImportedStandards = res.data.data.data;
          this.nonImportedFilters.page = res.data.data.current_page;
          this.nonImportedPagination.perPage = res.data.data.per_page;
          this.nonImportedPagination.total = res.data.data.total;
          this.nonImportedPagination.lastPage = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    Next() {
      this.ischoosed = true;
    },
    Previous() {
      this.ischoosed = false;
    },
    loadGapInitModal() {
      this.$refs["initiate-gp"].show();
    },
    hideGapInitModal() {
      this.$refs["initiate-gp"].hide();
    },
    // handleTabClick(){
    //   this.componentKey += 1;
    //   console.log("reload child component");
    // },
    load() {
      this.getCurrentUserDetails()
        .then((res) => {
          this.$store.dispatch("app/setCurrentUserDetails", res.data.data, {
            root: true,
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
      if (this.userIsVendor() == true) {
        this.getTPRAssessmentsAndSetData(
          { page: 1, limit: 5 },
          this.$store.state.app.user.vendor_id
        );
      } else {
      }
    },

    handleTaskClick(taskId) {
      this.$router.push({
        path: `/tasks`,
        replace: true,
        query: {
          task_id: taskId,
        },
      });
    },
    userIsVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTPRAssessmentsAndSetData(filters, vendorId) {
      this.getAssessmentsOfVendor(filters, vendorId)
        .then((res) => {
          this.trpAssessments = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    handleVendorAssessmentViewAllClick() {
      this.$router.push(`/vendorassessments`);
    },
    handleAssessmentClick(id) {
      this.$router.push(`/vendorassessments/${id}`);
    },
    handleRowClick(id) {
      this.$router.push({
        name: "control-gap-asssessment",
        params: { id: id },
      });
    },
    getTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/tasks?page=1&status=1&limit=5`,
      };
      this.$http(options)
        .then((resp) => {
          this.tasks = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getGaps(uid) {
      console.log("Get Gaps", "1675938132510");
      console.log(this.$store.state.app.user.user_id + "inside function");
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/gap-assessments`,
        params: {
          limit: 5,
          // assessed_by: this.$store.state.app.user.user_id,
        },
      };
      this.$http(options)
        .then((resp) => {
          this.gaps = resp.data.data.data;
          if (this.gaps.length == 0) {
            this.loadGapInitModal();
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    gotoTask() {
      this.$router.push("/tasks");
    },
    gotogaps() {
      this.$router.push("/gapassessment");
    },
  },
};
</script>

<style lang="scss" scoped>
.misc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
